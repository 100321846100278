import React, {Component} from 'react';
import ProjectHeader from "./ProjectHeader";
import NextSection from "./NextSection";

class ProjectItem extends Component {
    render() {
        const {Projects, slug} = this.props;

        return (
            <>
                {
                    Projects.map((pro, i) => (
                        pro.slug === slug &&
                        <>
                            <ProjectHeader key={i}
                                           Image={pro.BackgroundImage}
                                           date={pro.date} title={pro.title}
                                           client={pro.client} mainTitle={pro.mainTitle}
                                           category={pro.category} tags={pro.tags}/>

                            <section className="intro-section section-padding">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="htit">
                                                <h4><span>01 </span> Introduction</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 offset-lg-1 col-md-8">
                                            <div className="text js-scroll__content">
                                                <p className="extra-text">{pro.introductionText}</p>

                                                <ul className="smp-list mt-30">
                                                    {
                                                        pro.introductionList.map((listItem, i) => (
                                                            <li key={i}>{listItem}</li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="projdtal">
                                <div className="justified-gallery">
                                    {
                                        pro.gallery.map((galleryItem, i) => (
                                            <a href={'#0'} key={i}>
                                                <img alt="gallery" src={galleryItem} />
                                            </a>
                                        ))
                                    }

                                </div>
                            </section>

                            <section className="intro-section section-padding">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="htit">
                                                <h4><span>02 </span> Description</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 offset-lg-1 col-md-8 mb-30">
                                            <div className="text js-scroll__content">
                                                <p className="extra-text">{pro.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className="container-fluid">
                                    <div className="video-wrapper section-padding bg-img parallaxie valign"
                                         data-background={pro.BackgroundImage} data-overlay-dark="4">
                                        <div className="full-width text-center">
                                            <a className="vid" href={pro.videoSrc}>
                                                <div className="vid-butn">
                                <span className="icon">
                                    <i className="fas fa-play"></i>
                                </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <NextSection title={pro.nextSection.title}
                                         BackgroundImage={pro.nextSection.BackgroundImage}
                                         slug={pro.nextSection.slug} />
                        </>
                    ))
                }





            </>
        );
    }
}

export default ProjectItem;
