import React, {Component} from 'react';

class ContactHeader extends Component {
    render() {
        return (
            <>
                <header className="works-header fixed-slider hfixd valign">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-11 static">
                                <div className="capt mt-50">
                                    <div className="parlx">
                                        <h2 className="custom-font"><span>Let's</span>Talk About Your project.</h2>
                                        <p>Feel free to ask me any question or let’s do to talk about
                                            our future collaboration.</p>
                                    </div>

                                    <div className="bactxt custom-font valign">
                                        <span className="full-width">Contact</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default ContactHeader;