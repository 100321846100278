import React, {Component} from 'react';
import ProjectItem from "./ProjectItem";
import ProjectFooter from "./ProjectFooter";
import Preloader from "../global/Preloader";

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectData: [
                {
                    title: "web design & web development",
                    mainTitle: "Website 01",
                    client: "Secret for client privacy",
                    date: "Jan 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'This website has been designed according to modern design standards The site consists of several pages that contain a description and pictures of the customer’s services, according to his request Techniques used on site:',
                    introductionList: ["HTML, HTML5","CSS, CSS3","JS, Bootstrap"],
                    gallery: ["/img/portfolio/project1/1.jpg","/img/portfolio/project1/2.jpg","/img/portfolio/project1/3.jpg","/img/portfolio/project1/4.jpg","/img/portfolio/project1/5.jpg","/img/portfolio/project1/6.jpg","/img/portfolio/project1/7.jpg"],
                    description: "The site is specially designed for real estate marketing companies, resorts and hotels",
                    BackgroundImage: "/img/portfolio/project1/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=ZT4Box7K_y4?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-01",
                    nextSection: {
                        title:"Website 02",
                        slug:"website-02",
                        BackgroundImage: "/img/portfolio/project1/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 02",
                    client: "Secret for client privacy",
                    date: "Feb 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A dynamic company website designed from many pages and sections that supports more than one language, The techniques used in creating the site:',
                    introductionList: ["HTML5, CSS3","Javascript","PHP 7x, Laravel 5.7","Mysql"],
                    gallery: ["/img/portfolio/project2/1.jpg","/img/portfolio/project2/2.jpg","/img/portfolio/project2/3.jpg","/img/portfolio/project2/4.jpg","/img/portfolio/project2/5.jpg","/img/portfolio/project2/6.jpg","/img/portfolio/project2/7.jpg"],
                    description: "The site is specially designed for alarm, firefighting and industrial safety systems companies.",
                    BackgroundImage: "/img/portfolio/project2/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=btPU5akkQZc?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-02",
                    nextSection: {
                        title:"Website 03",
                        slug:"website-03",
                        BackgroundImage: "/img/portfolio/project2/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 03",
                    client: "Secret for client privacy",
                    date: "Feb 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A site designed from many sections and pages and the site supports many languages. The site was designed and developed according to the terms and specifications of the customer Techniques used on site.',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project3/1.jpg","/img/portfolio/project3/2.jpg","/img/portfolio/project3/3.jpg","/img/portfolio/project3/4.jpg","/img/portfolio/project3/5.jpg","/img/portfolio/project3/6.jpg","/img/portfolio/project3/7.jpg"],
                    description: "A huge website dedicated to companies and institutions with many services for companies and individuals, which have been added under the customer's supervision.",
                    BackgroundImage: "/img/portfolio/project3/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=VGVQBWffq0o?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-03",
                    nextSection: {
                        title:"Website 04",
                        slug:"website-04",
                        BackgroundImage: "/img/portfolio/project3/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 04",
                    client: "Secret for client privacy",
                    date: "Mar 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A one page website designed with colors and a modern user interface Technologies used in website design:',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project4/1.jpg","/img/portfolio/project4/2.jpg","/img/portfolio/project4/3.jpg","/img/portfolio/project4/4.jpg","/img/portfolio/project4/5.jpg","/img/portfolio/project4/6.jpg","/img/portfolio/project4/7.jpg"],
                    description: "A website designed for small businesses and startups with attractive colors and a modern user interface compatible with all types of devices.",
                    BackgroundImage: "/img/portfolio/project4/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=aHfb-UvaI2M?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-04",
                    nextSection: {
                        title:"Website 05",
                        slug:"website-05",
                        BackgroundImage: "/img/portfolio/project4/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 05",
                    client: "Secret for client privacy",
                    date: "Mar 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A one page site designed as a profile of the client company Technologies used in website development:',
                    introductionList: ["HTML5, CSS3","Javascript","Angular 8"],
                    gallery: ["/img/portfolio/project5/1.jpg","/img/portfolio/project5/2.jpg","/img/portfolio/project5/3.jpg","/img/portfolio/project5/4.jpg","/img/portfolio/project5/5.jpg","/img/portfolio/project5/6.jpg","/img/portfolio/project5/7.jpg"],
                    description: "A one-page site designed and considered a profile of the client company. The site contains information about the client’s group of companies and their most important services.",
                    BackgroundImage: "/img/portfolio/project5/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=qp9bJYJ08T4?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-05",
                    nextSection: {
                        title:"Website 06",
                        slug:"website-06",
                        BackgroundImage: "/img/portfolio/project5/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 06",
                    client: "Secret for client privacy",
                    date: "Apr 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A large website designed from many pages and sections with a modern and distinctive design that matches the corporate identity Technologies used on the site',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project6/1.jpg","/img/portfolio/project6/2.jpg","/img/portfolio/project6/3.jpg","/img/portfolio/project6/4.jpg","/img/portfolio/project6/5.jpg","/img/portfolio/project6/6.jpg","/img/portfolio/project6/7.jpg"],
                    description: "A large website through which the customer provides many of the company's services and the site classification is a site for contracting work, light current and technical services",
                    BackgroundImage: "/img/portfolio/project6/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=msM8CzoAQdM?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-06",
                    nextSection: {
                        title:"Website 07",
                        slug:"website-07",
                        BackgroundImage: "/img/portfolio/project6/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 07",
                    client: "Secret for client privacy",
                    date: "Apr 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: "A website completely designed according to the customer's vision, containing many sections and pages, and is compatible with all devices Technologies used in website design",
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project7/1.jpg","/img/portfolio/project7/2.jpg","/img/portfolio/project7/3.jpg","/img/portfolio/project7/4.jpg","/img/portfolio/project7/5.jpg","/img/portfolio/project7/6.jpg","/img/portfolio/project7/7.jpg"],
                    description: "The site is specially designed for cleaning services companies.",
                    BackgroundImage: "/img/portfolio/project7/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=PegotdIEc34?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-07",
                    nextSection: {
                        title:"Website 08",
                        slug:"website-08",
                        BackgroundImage: "/img/portfolio/project7/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 08",
                    client: "Secret for client privacy",
                    date: "May 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'Website designed according to customer standards The website supports more than one language and is compatible with all devices Design techniques',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4","Wordpress"],
                    gallery: ["/img/portfolio/project8/1.jpg","/img/portfolio/project8/2.jpg","/img/portfolio/project8/3.jpg","/img/portfolio/project8/4.jpg","/img/portfolio/project8/5.jpg","/img/portfolio/project8/6.jpg","/img/portfolio/project8/7.jpg"],
                    description: "A website for hygiene and environmentally friendly products that offers many products used in most specialties.",
                    BackgroundImage: "/img/portfolio/project8/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=K1e7Cz1Fe-s?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-08",
                    nextSection: {
                        title:"Website 09",
                        slug:"website-09",
                        BackgroundImage: "/img/portfolio/project8/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 09",
                    client: "Secret for client privacy",
                    date: "May 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A site designed from one page to prepare a profile for the company The site is compatible with all devices Technologies used in website design.',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project9/1.jpg","/img/portfolio/project9/2.jpg","/img/portfolio/project9/3.jpg","/img/portfolio/project9/4.jpg","/img/portfolio/project9/5.jpg","/img/portfolio/project9/6.jpg","/img/portfolio/project9/7.jpg"],
                    description: "A website specially designed for the field of medicine (hospitals and medical supplies).",
                    BackgroundImage: "/img/portfolio/project9/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=NnChlcsmLmw?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-09",
                    nextSection: {
                        title:"Website 10",
                        slug:"website-10",
                        BackgroundImage: "/img/portfolio/project9/ico.jpg"
                    }
                },
                {
                    title: "web design & web development",
                    mainTitle: "Website 10",
                    client: "Secret for client privacy",
                    date: "Jun 2020",
                    category: '<a href="#0">Web Design </a>, <a href="#0">Web development</a>',
                    tags: '<a href="#0">Web</a> , <a href="#0">Development</a> , <a href="#0">Design</a>',
                    introductionText: 'A one page website designed with colors and a modern user interface Technologies used in website design:',
                    introductionList: ["HTML5, CSS3","Javascript","Bootstrap 4"],
                    gallery: ["/img/portfolio/project10/1.jpg","/img/portfolio/project10/2.jpg","/img/portfolio/project10/3.jpg","/img/portfolio/project10/4.jpg","/img/portfolio/project10/5.jpg","/img/portfolio/project10/6.jpg","/img/portfolio/project10/7.jpg"],
                    description: "A website designed for small businesses and startups with attractive colors and a modern user interface compatible with all types of devices.",
                    BackgroundImage: "/img/portfolio/project10/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=wNmCXGp7NuI?vq=hd1080&autoplay=1&loop=1&showinfo=0&rel=0",
                    slug: "website-10",
                    nextSection: {
                        title:"Website 1",
                        slug:"website-1",
                        BackgroundImage: "/img/portfolio/project10/ico.jpg"
                    }
                },
                {
                    title: "art & Graphic Design",
                    mainTitle: "Corporate identities",
                    client: "Secret for client privacy",
                    date: "None",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Publications </a>',
                    tags: '<a href="#0">Graphic Design</a> , <a href="#0">Publications</a>',
                    introductionText: 'We can help you create the visual and paper identity of your new company or project Software used in the design :',
                    introductionList: ["Adobe Photoshop","Adobe Illustrator","Adobe Indesign"],
                    gallery: ["/img/portfolio/project11/1.jpg","/img/portfolio/project11/2.jpg","/img/portfolio/project11/3.jpg","/img/portfolio/project11/4.jpg","/img/portfolio/project11/5.jpg","/img/portfolio/project11/6.jpg","/img/portfolio/project11/7.jpg"],
                    description: "A visual or paper identity is indispensable for all companies, regardless of their size in turn, we offer a lot of designs and options that suit all customer needs to help them create and design visual identities for their business.",
                    BackgroundImage: "/img/portfolio/project11/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=9rGTphRkkqs",
                    slug: "identity",
                    nextSection: {
                        title:"Business cards",
                        slug:"business-card",
                        BackgroundImage: "/img/portfolio/project11/ico.jpg"
                    }
                },
                {
                    title: "art & Graphic Design",
                    mainTitle: "Business cards",
                    client: "Secret for client privacy",
                    date: "None",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Publications </a>',
                    tags: '<a href="#0">Graphic Design</a> , <a href="#0">Publications</a>',
                    introductionText: 'We are always ready for everything that is innovative and new for our customers in the world of design, and among the services we provide is the service of designing business cards with the latest elegant designs that suit all markets and all fields.',
                    introductionList: ["Adobe Photoshop","Adobe Illustrator"],
                    gallery: ["/img/portfolio/project12/1.jpg","/img/portfolio/project12/2.jpg","/img/portfolio/project12/3.jpg","/img/portfolio/project12/4.jpg","/img/portfolio/project12/5.jpg","/img/portfolio/project12/6.jpg","/img/portfolio/project12/7.jpg"],
                    description: "Business cards are indispensable for every person, as they are the appropriate identification tools in the business field",
                    BackgroundImage: "/img/portfolio/project12/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=EVHrl2qzsI0",
                    slug: "business-card",
                    nextSection: {
                        title:"Brochure",
                        slug:"brochure",
                        BackgroundImage: "/img/portfolio/project12/ico.jpg"
                    }
                },
                {
                    title: "art & Graphic Design",
                    mainTitle: "Brochure",
                    client: "Secret for client privacy",
                    date: "None",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Publications </a>',
                    tags: '<a href="#0">Graphic Design</a> , <a href="#0">Publications</a>',
                    introductionText: 'One of the best and oldest advertising methods is paper advertising, as it enables you to describe your company or services in the smallest detail Software used in the design:',
                    introductionList: ["Adobe Photoshop","Adobe Illustrator","Adobe Indesign"],
                    gallery: ["/img/portfolio/project13/1.jpg","/img/portfolio/project13/2.jpg","/img/portfolio/project13/3.jpg","/img/portfolio/project13/4.jpg","/img/portfolio/project13/5.jpg","/img/portfolio/project13/6.jpg","/img/portfolio/project13/7.jpg"],
                    description: "And we, in turn, are able to put a distinctive and elegant stamp on all the publications of your company or your new project.",
                    BackgroundImage: "/img/portfolio/project13/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=-bcVKM-3aa0",
                    slug: "brochure",
                    nextSection: {
                        title:"Social media",
                        slug:"social-media",
                        BackgroundImage: "/img/portfolio/project13/ico.jpg"
                    }
                },
                {
                    title: "art & Graphic Design",
                    mainTitle: "Social media",
                    client: "Secret for client privacy",
                    date: "None",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Social Media </a>',
                    tags: '<a href="#0">Graphic Design</a> , <a href="#0">Social Media</a>',
                    introductionText: 'Now it is impossible to rely solely on a paper or television propagandist, so it is necessary for every business or company to rely on social media in marketing its products or services Software used in the design',
                    introductionList: ["Adobe Photoshop","Adobe Illustrator","Adobe After effects"],
                    gallery: ["/img/portfolio/project14/1.jpg","/img/portfolio/project14/2.jpg","/img/portfolio/project14/3.jpg","/img/portfolio/project14/4.jpg","/img/portfolio/project14/5.jpg","/img/portfolio/project14/6.jpg","/img/portfolio/project14/7.jpg"],
                    description: "Here comes our role. We can help you with creative designs and new ideas and publish them on your social media platforms",
                    BackgroundImage: "/img/portfolio/project14/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=8qjJ537QDu4",
                    slug: "social-media",
                    nextSection: {
                        title:"Corporate identities",
                        slug:"identity",
                        BackgroundImage: "/img/portfolio/project14/ico.jpg"
                    }
                },
                {
                    title: "Web development",
                    mainTitle: "ProMS",
                    client: "AllandMuchMore",
                    date: "Mar 2020",
                    category: '<a href="#0">Web development </a>, <a href="#0"> Systems </a>',
                    tags: '<a href="#0">Web development</a> , <a href="#0">Systems</a>',
                    introductionText: 'ProMS– Ultimate Project Manager is a multipurpose project management system. You can use it to manage projects, clients, invoices, support tickets, estimates, team and many other purposes. It is suitable for different types of organizations, freelancers and individual users. ProMS is fast and easy to use. It contains all essential tools to manage your business. Technologies used to develop the system:',
                    introductionList: ["HTML5, CSS3","	JavaScript JS","PHP 7.x","CodeIgniter","MySQL"],
                    gallery: ["/img/portfolio/project15/1.jpg","/img/portfolio/project15/2.jpg","/img/portfolio/project15/3.jpg","/img/portfolio/project15/4.jpg","/img/portfolio/project15/5.jpg","/img/portfolio/project15/6.jpg","/img/portfolio/project15/7.jpg"],
                    description: "We can support you after purchasing any of our products by adding, deleting or modifying them to suit the nature and field of your business.",
                    BackgroundImage: "/img/portfolio/project15/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=DBDG6OP2pFg",
                    slug: "proms",
                    nextSection: {
                        title:"SM POS",
                        slug:"smpos",
                        BackgroundImage: "/img/portfolio/project15/ico.jpg"
                    }
                },
                {
                    title: "Web development",
                    mainTitle: "SM P.O.S",
                    client: "AllandMuchMore",
                    date: "Jul 2020",
                    category: '<a href="#0">Web development </a>, <a href="#0"> Systems </a>',
                    tags: '<a href="#0">Web development</a> , <a href="#0">Systems</a>',
                    introductionText: 'SM P.O.S web application that allows you to manage your sales and inventory on site. Shop Module brings the AllandMuchMore to next level by allowing you to easily sell your products online from your website, accept PayPal & Skrill payments. This module would help your customer to manager their sales, quotes and payments. Technologies used to develop the system:',
                    introductionList: ["HTML5, CSS3","	JavaScript JS","PHP 7.x","CodeIgniter","MySQL"],
                    gallery: ["/img/portfolio/project16/1.jpg","/img/portfolio/project16/2.jpg","/img/portfolio/project16/3.jpg","/img/portfolio/project16/4.jpg","/img/portfolio/project16/5.jpg","/img/portfolio/project16/6.jpg","/img/portfolio/project16/7.jpg"],
                    description: "POS (Point of Sale) Module would help your staff to easily sell your products and accept credit card payments. Easily access pos from your device with internet connection and this module is touch screen friendly.",
                    BackgroundImage: "/img/portfolio/project16/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=YRUFa5QsL58",
                    slug: "smpos",
                    nextSection: {
                        title:"lets diet",
                        slug:"letsdiet",
                        BackgroundImage: "/img/portfolio/project17/ico.jpg"
                    }
                },
                {
                    title: "Web development",
                    mainTitle: "Lets Diet",
                    client: "Secret for client privacy",
                    date: "Agu 2020",
                    category: '<a href="#0">Web development </a>, <a href="#0"> Systems </a>',
                    tags: '<a href="#0">Web development</a> , <a href="#0">Systems</a>',
                    introductionText: 'lets diet is the perfect combination of a trendy person healthy lifestyle that includes exercise and commitment. We are providing our guidance for our clients to achieve their desired results through our well experienced team and services. Technologies used to develop the system:',
                    introductionList: ["JavaScript JSON","JavaScript JS","XML, Cordova, Ionic, PhoneGap"],
                    gallery: ["/img/portfolio/project17/1.jpg","/img/portfolio/project17/2.jpg","/img/portfolio/project17/3.jpg","/img/portfolio/project17/4.jpg","/img/portfolio/project17/5.jpg","/img/portfolio/project17/6.jpg","/img/portfolio/project17/7.jpg"],
                    description: "We can support you after purchasing any of our products by adding, deleting or modifying them to suit the nature and field of your business.",
                    BackgroundImage: "/img/portfolio/project17/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=ib5j6l8KTyY",
                    slug: "letsdiet",
                    nextSection: {
                        title:"Qcom",
                        slug:"qcom",
                        BackgroundImage: "/img/portfolio/project18/ico.jpg"
                    }
                },
                {
                    title: "Web development",
                    mainTitle: "Qcom - Queue Management System",
                    client: "AllandMuchMore",
                    date: "Oct 2020",
                    category: '<a href="#0">Web development </a>, <a href="#0"> Systems </a>',
                    tags: '<a href="#0">Web development</a> , <a href="#0">Systems</a>',
                    introductionText: 'Qcom - Queue Management System is a most powerful and easiest queue token system. It will help to manage customer/client waiting list and allocation processes in an organized way. It consist of Ticket is responsible for issuing the ticket, it can connect on a printer for printing, Service can have its ticket series/serial numbering with the department, counter and officer name. Technologies used to develop the system:',
                    introductionList: ["HTML5, CSS3","	JavaScript JS","PHP 7.x","Laravel","MySQL"],
                    gallery: ["/img/portfolio/project18/1.jpg","/img/portfolio/project18/2.jpg","/img/portfolio/project18/3.jpg","/img/portfolio/project18/4.jpg","/img/portfolio/project18/5.jpg","/img/portfolio/project18/6.jpg","/img/portfolio/project18/7.jpg"],
                    description: "There are three types of user account (Admin, Officer, and Staff). Ticket series/serial (A001, A002, A003…) respectively assigned on each service.",
                    BackgroundImage: "/img/portfolio/project18/bg.jpg", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=szD4MWakFsA",
                    slug: "qcom",
                    nextSection: {
                        title:"ProMS",
                        slug:"proms",
                        BackgroundImage: "/img/portfolio/project18/ico.jpg"
                    }
                },
                {
                    title: "Motion & Graphic Design",
                    mainTitle: "Motorgy App Promo",
                    client: "Motorgy",
                    date: "Oct 2020",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Motion</a>',
                    tags: '<a href="#0">Motion Graphic</a> , <a href="#0">3D Design</a>',
                    introductionText: 'The basis of any motion graphic design is the idea, creativity, mastery and craftsmanship in implementing the idea The programs used to implement the promo',
                    introductionList: ["Cinema 4D","Adobe After effects","Adobe Premiere"],
                    gallery: ["/img/portfolio/project19/1.gif","/img/portfolio/project19/2.gif","/img/portfolio/project19/3.gif","/img/portfolio/project19/4.gif","/img/portfolio/project19/5.gif","/img/portfolio/project19/6.gif","/img/portfolio/project19/7.gif"],
                    description: "Mobile app teaser with some information about the app.",
                    BackgroundImage: "/img/portfolio/project19/1.gif", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=AYu7nxn2XrA",
                    slug: "motorgy",
                    nextSection: {
                        title:"Store App Promo",
                        slug:"motion-promo",
                        BackgroundImage: "/img/portfolio/project19/ico.jpg"
                    }
                },
                {
                    title: "Motion & Graphic Design",
                    mainTitle: "Store App Promo",
                    client: "Secret for client privacy",
                    date: "Feb 2020",
                    category: '<a href="#0">Graphic Design </a>, <a href="#0"> Motion</a>',
                    tags: '<a href="#0">Motion Graphic</a> , <a href="#0">3D Design</a>',
                    introductionText: 'The basis of any motion graphic design is the idea, creativity, mastery and craftsmanship in implementing the idea The programs used to implement the promo',
                    introductionList: ["Cinema 4D","Adobe After effects","Adobe Premiere"],
                    gallery: ["/img/portfolio/project20/1.gif","/img/portfolio/project20/2.gif","/img/portfolio/project20/3.gif","/img/portfolio/project20/4.gif","/img/portfolio/project20/5.gif","/img/portfolio/project20/6.gif","/img/portfolio/project20/7.gif"],
                    description: "Mobile app teaser with some information about the app.",
                    BackgroundImage: "/img/portfolio/project20/1.gif", // for header background and video
                    videoSrc: "https://www.youtube.com/watch?v=uREGvWet0hA",
                    slug: "motion-promo",
                    nextSection: {
                        title:"Motorgy App Promo",
                        slug:"motorgy",
                        BackgroundImage: "/img/portfolio/project20/ico.jpg"
                    }
                },
            ],
        };
    }

    render() {
        const {projectData} = this.state;
        const {projectName} = this.props;
        return (
            <>
                <Preloader/>
                <div className="wrapper">
                    <ProjectItem Projects={projectData} slug={projectName}/>
                    <ProjectFooter/>
                </div>
            </>
        );
    }
}

export default Project;


