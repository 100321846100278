import React, {Component} from 'react';
import HeroSlideItem from "./HeroSlideItem";

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heroSlider: [
                {
                    backgroundImage: "img/slid/d1.webp",
                    headerText: "Graphic Design",
                    contentText: "We provide all kinds of design services, " +
                        "such as designing logos and printed matter," +
                        " as well as motion videos",
                    btn: {btnLink: "/works#graphic-design", btnText: "Look More"}
                },
                {
                    backgroundImage: "img/slid/d2.webp",
                    headerText: "Web Design",
                    contentText: "Treating and designing a website through your real and effective online presence",
                    btn: {btnLink: "/works#web-design", btnText: "Learn More"}
                },
                {
                    backgroundImage: "img/slid/d3.webp",
                    headerText: "Web Development",
                    contentText: "Our team are experienced in building a range of complex systems",
                    btn: {btnLink: "/works#web-development", btnText: "See More"}
                },
                {
                    backgroundImage: "img/slid/d4.webp",
                    headerText: "Mobile App  <br/> Development",
                    contentText: "If you have a special project idea, you will need a mobile application design and programming service",
                    btn: {btnLink: "/works#mobile-development", btnText: "Go Now"}
                },  // add comma here if you want to add more ( , )
                {
                    backgroundImage: "img/slid/d5.webp",
                    headerText: "Digital marketing",
                    contentText: "The best digital marketing company in the MENA region and our results prove it",
                    btn: {btnLink: "/works#digital-marketing", btnText: "Go Now"}
                }  // add comma here if you want to add more ( , )

            ]
        };
    }

    render() {
        const {heroSlider} = this.state;
        return (
            <>
                <header className="slider slider-prlx fixed-slider text-center">
                    <div className="swiper-container parallax-slider">
                        <div className="swiper-wrapper">
                            {/* slide item */}
                            <HeroSlideItem heroSlider={heroSlider}/>
                            {/* slide item */}

                        </div>

                        <div className="setone setwo">
                            <div className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer">
                                <i className="fas fa-chevron-right"></i>
                            </div>
                            <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                        </div>
                        <div className="swiper-pagination top botm custom-font"></div>

                        <div className="social-icon">

                            <a href="https://www.facebook.com/AllandMuchMore.CO/">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://twitter.com/AllAndMuchMore2">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCvNiqQCSeYWgiD5Ez1wDRzQ">
                                <i className="fab fa-youtube"></i>
                            </a>

                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default HomeHeader;
