import React, {Component} from 'react';
import {Link} from "@reach/router";

class ShowcaseNav extends Component {
    render() {
        return (
            <>
                <div id="navi" className="topnav">
                    <div className="container-fluid">
                        <div className="img-logo">
                            <a href="#0"><img src="/img/logo-light.png" alt="allandmuchmore logo" /></a>
                        </div>
                        <div className="menu-icon">
                <span className="icon">
                    <i></i>
                    <i></i>
                </span>
                            <span className="text" data-splitting>Menu</span>
                        </div>
                    </div>
                </div>

                <div className="hamenu">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-8">
                                <div className="menu-links">
                                    <ul className="main-menu">

                                        <li>
                                            <div className="o-hidden">
                                                <Link to="/" onClick={() => window.location.replace('/')} className="link"><span className="nm">01.</span>Home</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="o-hidden">
                                                <Link to="/about" onClick={() => window.location.replace('/about')} className="link"><span className="nm">02.</span>About</Link>
                                            </div>
                                        </li>


                                        <li>
                                            <div className="o-hidden">
                                                <Link to="/works" onClick={() => window.location.replace('/works')} className="link"><span className="nm">04.</span>Works</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="o-hidden">
                                                <Link to="/contact" onClick={() => window.location.replace('/contact')} className="link"><span className="nm">05.</span>Contact</Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="cont-info">
                                    <div className="item">
                                        
                                    </div>
                                    <div className="item">
                                        <h6>Address :</h6>
                                        <p>Somewhere on planet earth</p>
                                    </div>
                                    <div className="item">
                                        <h6>Email :</h6>
                                        <p><a href="mailto:info@allandmuchmore.com">info@allandmuchmore.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ShowcaseNav;