import React, {Component} from 'react';
import WorksHeader from "./WorksHeader";
import WorksFooter from "./WorksFooter";
import WorksContent from "./WorksContent";
import Preloader from "../global/Preloader";

class Works extends Component {
    render() {
        return (
            <>
                <Preloader/>
                <WorksHeader/>
                <div className="main-content">
                    <WorksContent/>
                    <WorksFooter/>
                </div>
            </>
        );
    }
}

export default Works;
