import React, {Component} from 'react';

class HomeCounterSection extends Component {
    render() {
        return (
            <>
                <section className="number-sec section-padding sub-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="item no-bord">
                                    <span className="icon pe-7s-magic-wand"></span>
                                    <h3 className="custom-font"><span className="count">73</span></h3>
                                    <p className="wow txt" data-splitting="words">Graphic Project</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="item">
                                    <span className="icon pe-7s-phone"></span>
                                    <h3 className="custom-font"><span className="count">11</span></h3>
                                    <p className="wow txt" data-splitting="words">Mobile Projects</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="item">
                                    <span className="icon pe-7s-science"></span>
                                    <h3 className="custom-font"><span className="count">19</span></h3>
                                    <p className="wow txt" data-splitting="words">web development & design</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="item">
                                    <span className="icon pe-7s-speaker"></span>
                                    <h3 className="custom-font"><span className="count">15</span></h3>
                                    <p className="wow txt" data-splitting="words">Digital Campaign</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeCounterSection;
