import React, {Component} from 'react';

class AboutMiniArea extends Component {
    render() {
        return (
            <>
                <section className="min-area sub-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="img">
                                    <img className="thumparallax-down" src="/img/min-area.jpg" alt="mini area" />
                                </div>
                            </div>
                            <div className="col-lg-6 valign">
                                <div className="content">
                                    <h4 className="wow custom-font" data-splitting>We are not only a marketing company,</h4>
                                    <p className="wow txt" data-splitting>
                                        but the efforts made by the AllandMuchMore team have achieved what we are looking for in all digital fields,
                                        and our ambition did not stop at the successes we have achieved, but we are still continuing to succeed,
                                        so what services are you looking for? Are you still looking to learn more about digital services?
                                        And you want to learn what is e-marketing from A to Z? So the solution is now with us at AllandMuchMore.
                                    </p>
                                    <ul className="feat">
                                       
                                        <li className="wow fadeInUp" data-wow-delay=".4s">
                                            <h6><span>1</span> our vision</h6>
                                            <p>
                                                At AllandMuchMore, we aspire to reach the world as the best digital services company,
                                                and we know that this cannot come between day and night, and it will not come by wishful thinking alone.
                                                The Arab world or the whole world
                                            </p>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay=".6s">
                                            <h6><span>2</span> OUR MESSAGE </h6>
                                            <p>
                                                At AllandMuchMore, we strive to provide all digital services with high quality,
                                                hoping to achieve the ambition of customers aspiring to success and make money using everything new and innovative in digital marketing and digital services,
                                                The best solutions and technologies with AllandMuchMoreare now on the ground
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default AboutMiniArea;
