import React, {Component} from 'react';
import axios from "axios";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            subject: "",
            mailSent: false,
            error: false,
            successMessage: 'Thank you for contcting me.',
            errorMessage: 'Please Fill the complete form',
            send: false,
        }
        this.inputHandler = this.inputHandler.bind(this);
    }

    onSubmitHandler(event) {
        event.preventDefault();
        axios({
            method: "post",
            url: 'contact.php',
            headers: {"content-type": "application/json"},
            data: this.state
        })
            .then(result => {
                if (result.data.sent) {
                    this.setState({mailSent: result.data.sent})
                    this.setState({error: false});
                } else {
                    this.setState({error: true});
                }
            })
            .catch(error => this.setState({error: error.message}));
    }




    inputHandler(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {

        return (
            <>
                <form id="contact-form" method="post" action="http://localhost/allandmuchmoretech/contact.php">
                    {/*<form id="contact-form" method="post" onSubmit={this.onSubmitHandler}>*/}

                    {

                        this.props.success === "success" ?
                            <div className=" alert-success">success: your message has sent</div> :
                            <div className="messages"></div>
                    }
                    {

                        this.props.success === "fail" ?
                            <div className=" alert-danger">fail: your message has not sent .. please try again</div> :
                            <div className="messages"></div>
                    }
                    {/*{
                        this.state.error === true ?
                            <div className="messages alert-danger">{this.state.errorMessage}</div>
                            : <div className="messages alert-success">{this.state.successMessage}</div>
                    }*/}


                    <div className="controls">

                        <div className="form-group">
                            <input id="form_name" type="text" name="name" placeholder="Name"
                                   required="required" value={this.state.name} onChange={this.inputHandler}/>
                        </div>

                        <div className="form-group">
                            <input id="form_subject" type="text" name="subject" placeholder="Subject"
                                   required="required" value={this.state.subject} onChange={this.inputHandler}/>
                        </div>

                        <div className="form-group">
                            <input id="form_email" type="email" name="email" placeholder="Email"
                                   required="required" value={this.state.email} onChange={this.inputHandler}/>
                        </div>

                        <div className="form-group">
                                        <textarea id="form_message" name="message" placeholder="Message" rows="4"
                                                  required="required" value={this.state.message}
                                                  onChange={this.inputHandler}></textarea>
                        </div>

                        <button type="submit" className="btn-curve btn-lit">
                            <span>Send Message</span></button>
                    </div>
                </form>
            </>
        );
    }
}

export default ContactForm;