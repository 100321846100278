import React, {Component} from 'react';
import AboutIntro from "./AboutIntro";
import NextPage from "../global/NextPage";
import AboutService from "../global/AboutService";
import HomeVideoSection from "../home/HomeVideoSection";
import HomeSkillsCircleSection from "../home/HomeSkillsCircleSection";
/*import AboutTeam from "./AboutTeam";*/
import AboutMiniArea from "./AboutMiniArea";
import HomeClientsBrands from "../home/HomeClientsBrands";

class AboutContent extends Component {
    render() {
        return (
            <>
                <AboutIntro />
                <AboutService />
                <HomeVideoSection />
                <HomeSkillsCircleSection />
                {/*<AboutTeam />*/}
                <AboutMiniArea />
                <HomeClientsBrands />
                <NextPage NextPageInfo={{
                    smallHeader: "Let’s Talk",
                    bigHeader: "Our <b>Latest Project</b>.",
                    btn: {btnText: "See Now", btnLink: "/works"}
                }}/>
            </>
        );
    }
}

export default AboutContent;
