import React, {Component} from 'react';

class ClientReview extends Component {
    render() {
        const {Reviews} = this.props;
        return (
            <>
                {
                    Reviews.map((reviewItem, i) => (
                        <div className="item" key={i}>
                            <p>{reviewItem.reviewText}</p>
                            <div className="info">
                                <div className="img">
                                    <div className="img-box">
                                        <img src={reviewItem.clientImage} alt="client-review"/>
                                    </div>
                                </div>
                                <div className="cont">
                                    <div className="author">
                                        <h6 className="author-name custom-font">{reviewItem.author.authorName}</h6>
                                        <span className="author-details">{reviewItem.author.authorDetails}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </>
        );
    }
}

export default ClientReview;
