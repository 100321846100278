import React, {Component} from 'react';
import ContactHeader from "./ContactHeader";
import ContactFooter from "./ContactFooter";
import ContactContent from "./ContactContent";
import Preloader from "../global/Preloader";

class Contact extends Component {
    render() {
        return (
            <>
                <Preloader />
                <div className="main-content">
                    <ContactHeader />
                    <ContactContent success={this.props.success} fail={this.props.fail}/>
                    <ContactFooter />
                </div>
            </>
        );
    }
}

export default Contact;