import React, {Component} from 'react';

class ProjectHeader extends Component {
    render() {
        const {Image,title, client, date, mainTitle , category, tags} = this.props;
        console.log(Image);
        return (
            <>
                <section className="page-header proj-det bg-img parallaxie valign"
                         data-background={Image}
                         data-overlay-dark="4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-9">
                                <div className="cont">
                                    <h6>{title}</h6>
                                    <h2>{mainTitle}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="item mt-30">
                                    <h6>Client</h6>
                                    <p><a href={client}>{client}</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="item mt-30">
                                    <h6>Date</h6>
                                    <p>{date}</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="item mt-30">
                                    <h6>Categories</h6>
                                    <p dangerouslySetInnerHTML={{__html: category}}></p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="item mt-30">
                                    <h6>Tags</h6>
                                    <p dangerouslySetInnerHTML={{__html: tags}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ProjectHeader;
