import React, {Component} from 'react';
import GalleryItem from "./GalleryItem";

class WorksContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryItems: [
                {
                    slug: "website-01",
                    image: "img/portfolio/mas/01.jpg",
                    projectTitle: "Website 01",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-02",
                    image: "img/portfolio/mas/02.jpg",
                    projectTitle: "Website 02",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-03",
                    image: "img/portfolio/mas/03.jpg",
                    projectTitle: "Website 03",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-04",
                    image: "img/portfolio/mas/04.jpg",
                    projectTitle: "Website 04",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-05",
                    image: "img/portfolio/mas/05.jpg",
                    projectTitle: "Website 05",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-06",
                    image: "img/portfolio/mas/06.jpg",
                    projectTitle: "Website 06",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-07",
                    image: "img/portfolio/mas/07.jpg",
                    projectTitle: "Website 07",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-08",
                    image: "img/portfolio/mas/08.jpg",
                    projectTitle: "Website 08",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-09",
                    image: "img/portfolio/mas/09.jpg",
                    projectTitle: "Website 09",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "website-10",
                    image: "img/portfolio/mas/10.jpg",
                    projectTitle: "Website 10",
                    category: "web-design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "identity",
                    image: "img/portfolio/mas/11.jpg",
                    projectTitle: "Corporate identities",
                    category: "design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "business-card",
                    image: "img/portfolio/mas/12.jpg",
                    projectTitle: "Business Card",
                    category: "design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "brochure",
                    image: "img/portfolio/mas/13.jpg",
                    projectTitle: "Brochures",
                    category: "design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "social-media",
                    image: "img/portfolio/mas/14.jpg",
                    projectTitle: "Social media",
                    category: "design",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "proms",
                    image: "img/portfolio/mas/15.jpg",
                    projectTitle: "Pro MS CRM System",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "smpos",
                    image: "img/portfolio/mas/16.jpg",
                    projectTitle: "SM P.O.S",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "letsdiet",
                    image: "img/portfolio/mas/17.jpg",
                    projectTitle: "Lets Diet App",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "qcom",
                    image: "img/portfolio/mas/18.jpg",
                    projectTitle: "Qcom - Queue Management System",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "motorgy",
                    image: "img/portfolio/mas/19.jpg",
                    projectTitle: "Motorgy App Promo",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
                {
                    slug: "motion-promo",
                    image: "img/portfolio/mas/20.jpg",
                    projectTitle: "Store App Promo",
                    category: "development",
                    projectTag: '<><a href="#0">Web Design</a>, <a href="#0">web development</a></>',

                },
            ]
        }
    }

    render() {
        const {galleryItems} = this.state;
        return (

            <section className="portfolio section-padding pb-70">
                <div className="container">

                    <div className="gallery full-width">

                        {/* gallery item */}

                        <GalleryItem GalleryItems={galleryItems} />

                        {/*<div className="col-md-6 items graphic lg-mr">
                            <div className="item-img wow fadeInUp" data-wow-delay=".4s">
                                <a href="project-details.html">
                                    <img src="img/portfolio/mas/01.jpg" alt="image"/>

                                </a>
                            </div>
                            <div className="cont">
                                <h6>Creativity Demand</h6>
                                <span><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                            </div>
                        </div>*/}




                    </div>

                </div>
            </section>

        );
    }
}

export default WorksContent;
