import React, {Component} from 'react';

import ContactContainer from "./ContactContainer";

class ContactContent extends Component {
    render() {
        return (
            <>
                <ContactContainer success={this.props.success} fail={this.props.fail} />
            </>
        );
    }
}

export default ContactContent;