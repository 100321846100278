import React, {Component} from 'react';

class HomeClientsBrands extends Component {
    render() {
        return (
            <>
                <section className="clients section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 valign">
                                <div className="sec-head custom-font mb-0">
                                    <h6>Clients</h6>
                                    <h3>Our <br/> Clients.</h3>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div>
                                    <div className="row bord">
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                                <div className="img" >
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".6s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".8s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".4s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".7s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".5s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 brands">
                                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                                <div className="img">
                                                    <img src="img/clients/brands/01.webp" alt="client-brand" />
                                                        <a href="#0" className="link" data-splitting>Secret for client privacy</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeClientsBrands;
