import React from 'react';
import { Router } from "@reach/router"
import Nav from "./components/global/Nav";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Works from "./components/works/Works";
import Project from "./components/project/Project";
import Contact from "./components/contact/Contact";
import Showcase from "./components/showcase/Showcase";

function App(props) {

    return window.location.pathname.includes("showcase") ? (
        <>
            <NavRouters />
        </>
    ) : (
        <>
            <NavRouters />
            <Nav />
        </>
    );
}

function NavRouters() {
   /* let Home = () => <div>Home</div>
    let About = () => <div>about</div>*/


    return(
        <Router>
            <Home path="/" />
            <About path="/about" />
            <Works path="/works" />
            <Project path="/project" />
            <Project path="/project/:projectName/" />
            <Contact path="/contact" />
            <Contact path="/contact/:success" />
            <Contact path="/contact/:fail" />
            <Showcase path="/showcase/:showcaseSlug" />

        </Router>
    );
}

export default App;
