import React, {Component} from 'react';

class AboutIntro extends Component {
    render() {
        return (
            <>
                <section className="intro-section section-padding pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div className="htit sm-mb30">
                                    <h4>WHO WE ARE</h4>
                                </div>
                            </div>
                            <div className="col-lg-8 offset-lg-1 col-md-8">
                                <div className="text">
                                    <p className="wow txt" data-splitting>
                                        The year of our launch in the light of AllandMuchMore was not an ordinary year,
                                        and our launch in the sky of digital services and e-marketing was not a traditional thing,
                                        but rather it was a year of real commotion filled with enthusiasm and hope, and they were the two means that supported us until we reached success,
                                        as we have created a new boom in services Digital in all its forms, we were not like any social media company at all, rather we were exerting a great effort to make our success appear on the horizon,
                                        and to gain leadership as the best companies specialized in digital services at all, through the correct digital presence, we are the ideal choice to improve your visibility through search engines Whether by writing content or sponsored ads,
                                        or by improving the visibility of your activity through the APP Store for many smartphone and tablet companies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default AboutIntro;
