import React, {Component} from 'react';
import {Link} from "@reach/router";

class HomeServiceSection extends Component {
    render() {
        return (
            <>
                <section className="services">
                    <div className="container">
                        <div className="sec-head custom-font text-center">
                            <h6 className="wow fadeIn" data-wow-delay=".5s">Best Features</h6>
                            <h3 className="wow" data-splitting>Services.</h3>
                            <span className="tbg">Services</span>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 item-box bg-img wow fadeInLeft" data-wow-delay=".3s"
                                 data-background="img/1.webp">
                                <h4 className="custom-font">Best Of <br/> Our Features</h4>
                                <Link to="/about" onClick={() => window.location.assign('/about')}
                                   className="btn-curve btn-bord btn-lit mt-40"><span>See All Services</span></Link>
                            </div>
                            <div className="col-lg-3 col-md-6 item-box wow fadeInLeft" data-wow-delay=".5s">
                                <span className="icon pe-7s-paint-bucket"></span>
                                <h6>Graphic Design</h6>
                                <p>We provide all kinds of design services, such as designing logos and printed matter, as well as motion videos</p>
                            </div>
                            <div className="col-lg-3 col-md-6 item-box wow fadeInLeft" data-wow-delay=".7s">
                                <span className="icon pe-7s-phone"></span>
                                <h6>Web & <br/> Mobile Design & Development</h6>
                                <p>Treating and designing a website through your real and effective online presence and mobile application design and programming service</p>
                            </div>
                            <div className="col-lg-3 col-md-6 item-box wow fadeInLeft" data-wow-delay=".9s">
                                <span className="icon pe-7s-display1"></span>
                                <h6>Digital <br/> Marketing</h6>
                                <p>The best digital marketing company in the MENA region and our results prove it</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-bg bottom"></div>
                </section>
            </>
        );
    }
}

export default HomeServiceSection;
