import React, {Component} from 'react';
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

class ContactContainer extends Component {
    render() {
        return (
            <>
                <section className="contact section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form md-mb50">

                                    <h4 className="extra-title mb-50">Get In Touch.</h4>

                                    <ContactForm success={this.props.success} fail={this.props.fail} />
                                </div>
                            </div>
                            <ContactInfo />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ContactContainer;