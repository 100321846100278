import React, {Component} from 'react';
import HomeFooter from "../home/HomeFooter";

class AboutFooter extends Component {
    render() {
        return (
            <>
                {/*<footer className="footer-half sub-bg section-padding pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="cont">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="img/logo-light.png" alt="logo" />
                                        </a>
                                    </div>
                                    <div className="con-info custom-font">
                                        <ul>
                                            <li><span>Email : </span> info@allandmuchmore.com</li>
                                            <li><span>Address : </span> A32 , Ave 15th Street, Door 211, San Franciso,
                                                USA
                                                32490.
                                            </li>
                                            <li><span>Phone : </span> (+1) 2345 678 44 88</li>
                                        </ul>
                                    </div>
                                    <div className="social-icon">
                                        <h6 className="custom-font stit simple-btn">Follow Us</h6>
                                        <div className="social">
                                            <a href="#0" className="icon">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#0" className="icon">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#0" className="icon">
                                                <i className="fab fa-pinterest"></i>
                                            </a>
                                            <a href="#0" className="icon">
                                                <i className="fab fa-behance"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-2">
                                <div className="subscribe mb-50">
                                    <h6 className="custom-font stit simple-btn">Newslatter</h6>
                                    <p>Sign up for subscribe out newsletter!</p>
                                    <form>
                                        <div className="form-group custom-font">
                                            <input type="email" name="subscribe" placeholder="Your Email" />
                                                <button className="cursor-pointer">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="insta">
                                    <h6 className="custom-font stit simple-btn">Instagram Post</h6>
                                    <div className="insta-gallary">
                                        <a href="#0">
                                            <img src="img/insta/1.jpg" alt=""/>
                                        </a>
                                        <a href="#0">
                                            <img src="img/insta/2.jpg" alt=""/>
                                        </a>
                                        <a href="#0">
                                            <img src="img/insta/3.jpg" alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyrights text-center">
                            <p>© 2020, AllAndMuchMore Developed by <a href="#0"> AllAndMuchMore Team</a>.</p>
                        </div>
                    </div>
                </footer>*/}
                <HomeFooter />
            </>
        );
    }
}

export default AboutFooter;
