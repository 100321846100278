import React, {Component} from 'react';
import {Link} from "@reach/router";

class GalleryItem extends Component {
    render() {
        const {GalleryItems} = this.props;
        return (
            <>
                {GalleryItems.map((item, i) => (
                    <div className="col-md-6 items graphic lg-mr" key={i}>
                        <div className="item-img wow fadeInUp" data-wow-delay=".4s">
                           {/* onClick={() => window.location.replace('/project/' + item.slug.replace(' ', '-').toLowerCase())}*/}

                                <img src={item.image} alt="projects" />

                        </div>
                        <div className="cont">
                            <h6>{item.projectTitle}</h6>
                            <Link className="btn btn-outline-primary m-3" to={'/project/' + item.slug.replace(' ', '-').toLowerCase()} onClick={() => window.location.replace('/project/' + item.slug.replace(' ', '-').toLowerCase())} >
                                Project Page
                            </Link>
                            <Link className="btn btn-outline-info" to={'/showcase/' + item.slug.replace(' ', '-').toLowerCase()} onClick={() => window.location.replace('/showcase/' + item.slug.replace(' ', '-').toLowerCase())} >
                                Showcase
                            </Link>
                            <br/>
                            <span dangerouslySetInnerHTML={{__html: item.projectTag}}></span>
                        </div>
                    </div>
                ))}

            </>
        );
    }
}

export default GalleryItem;
