import React, {Component} from 'react';
import {Link} from "@reach/router";

class ShowcaseSlide extends Component {
    render() {

        const {showcasesSlides, showcaseSlug} = this.props;
        //console.log(showcaseSlug);
        return (
            <>
                {
                    showcasesSlides.map((showcase) => showcase[showcaseSlug.toString()].map((slide, i) => (
                            <div className="swiper-slide" key={i}>
                                <div className="bg-img valign" data-background={slide.BackgroundImage}
                                     data-overlay-dark="4">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-10 offset-lg-1">
                                                <div className="caption">
                                                    <h1><Link to={"/project/" + showcaseSlug} onClick={() => window.location.replace("/project/" + showcaseSlug)} >
                                                        <div className="stroke" data-swiper-parallax="-2000">{slide.phase1}
                                                        </div>
                                                        <span
                                                            data-swiper-parallax="-5000">{slide.phase2}</span>
                                                    </Link></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))
                }
                {/**/}
            </>
        );
    }
}

export default ShowcaseSlide;