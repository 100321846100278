import React, {Component} from 'react';
import WorkSlideItem from "./WorkSlideItem";

class HomeWorkSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            works: [
                {
                    backgroundImage: "img/portfolio/metro/01.jpg",
                    category: {catLink: "#0", catText: "art & illustration"},
                    title: {titleLink: "/project-details", titleText: "Innovation and Crafts."}
                },
                {
                    backgroundImage: "img/portfolio/metro/02.jpg",
                    category: {catLink: "#0", catText: "Web Design"},
                    title: {titleLink: "/project-details", titleText: "Inspiring new space."}
                },
                {
                    backgroundImage: "img/portfolio/metro/03.jpg",
                    category: {catLink: "#0", catText: "Web Development"},
                    title: {titleLink: "/project-details", titleText: "Natural plus modern."}
                },
                {
                    backgroundImage: "img/portfolio/metro/04.jpg",
                    category: {catLink: "#0", catText: "Apps Development"},
                    title: {titleLink: "/project-details", titleText: "Innovation and Crafts."}
                },
                {
                    backgroundImage: "img/portfolio/metro/05.jpg",
                    category: {catLink: "#0", catText: "Digital Marketing"},
                    title: {titleLink: "/project-details", titleText: "Inspiring new space."}
                },
            ]
        };
    }

    render() {
        const {works} = this.state;
        return (
            <>
                <section className="work-carousel section-padding pt-0 metro position-re">
                    <div className="container ontop">
                        <div className="row">
                            <div className="col-lg-12 no-padding">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                        <WorkSlideItem worksItems={works}/>

                                    </div>

                                    <div className="swiper-button-next swiper-nav-ctrl simp-next cursor-pointer">
                                        <span className="simple-btn right">Next</span>
                                    </div>
                                    <div className="swiper-button-prev swiper-nav-ctrl simp-prev cursor-pointer">
                                        <span className="simple-btn">Prev</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="half-bg top"></div>
                </section>
            </>
        );
    }
}

export default HomeWorkSection;
