import React, {Component} from 'react';
import ShowcaseNav from "./ShowcaseNav";
import Preloader from "../global/Preloader";
import ShowcaseContainer from "./ShowcaseContainer";

class Showcase extends Component {

    render() {
        const {showcaseSlug} = this.props
        return (
            <>
                <Preloader />
                <ShowcaseNav />
                <ShowcaseContainer showcaseSlug={showcaseSlug}/>
            </>
        );
    }
}

export default Showcase;