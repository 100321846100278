import React, { Component } from 'react';

class AboutService extends Component {
    render() {
        return (
            <>
                <section className="services section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".3s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-magic-wand"></span>
                                    <h6>Graphic Design</h6>
                                    <p>Tempore corrupti temporibus fuga earum asperiores fugit laudantium.</p>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".5s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-gleam"></span>
                                    <h6>Digital Marketing</h6>
                                    <p>
                                        The best digital marketing company in the MENA region and our results prove it
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".7s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-global"></span>
                                    <h6>Web Design </h6>
                                    <p>
                                        Now start growing your business by creating and designing a website through your real and effective online presence,

                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".10s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-server"></span>
                                    <h6>Web Development</h6>
                                    <p>
                                        Our team are experienced in building a range of <strong>complex system </strong>
                                        e.g : LMS, CRM & ERP and E-commerce & Marketplaces
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".13s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-phone"></span>
                                    <h6>Mobile App Development</h6>
                                    <p><strong> Android & IOS </strong>:
                                        If you have a special project idea, you will need a mobile application design and programming service.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item md-mb50 wow fadeInUp" data-wow-delay=".16s" style={{ minHeight: '505px' }}>
                                    <span className="icon pe-7s-cloud"></span>
                                    <h6>Security</h6>
                                    <p>
                                        Testing and protecting corporate data, blocking phishing messages, inappropriate content, threats and malware, and preventing data loss. Test your own software such as mobile and web apps
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default AboutService;
