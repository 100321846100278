import React, {Component} from 'react';
import {Link} from "@reach/router";

class HeroSlideItem extends Component {

    render() {
        const heroSlider = this.props.heroSlider;

        return (
            <>
                {
                    heroSlider.map((slideitem, i) => (

                        <div className="swiper-slide" key={i}>
                            <div className="bg-img valign" data-background={slideitem.backgroundImage} data-overlay-dark="6">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 col-md-9">
                                            <div className="caption center">
                                                <h1 data-splitting dangerouslySetInnerHTML={{__html: slideitem.headerText}}></h1>
                                                <p>{slideitem.contentText}</p>
                                                <Link to={slideitem.btn.btnLink} className="btn-curve btn-lit mt-30">
                                                    <span>{slideitem.btn.btnText}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </>
        );
    }
}

export default HeroSlideItem;
