import React from 'react';
import { Link } from "@reach/router"

function Nav() {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">

                <Link className="logo" to="#">
                    <img className="img-logo" src="/img/logo-light.png" alt="logo" />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"><i className="fas fa-bars"></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link"  to="/" onClick={() => window.location.replace('/')} role="button"
                               aria-haspopup="true" >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about" onClick={() => window.location.replace('/about')}>About</Link>
                        </li>

                        <li className="nav-item ">
                            <Link className="nav-link "  to="/works" onClick={() => window.location.replace('/works')} role="button"
                               aria-haspopup="true" >Works</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact" onClick={() => window.location.replace('/contact')}> Contact</Link>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    );
}

export default Nav;
