import React, {Component} from 'react';
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import HomeContent from "./HomeContent";
import Preloader from "../global/Preloader";

class Home extends Component {
    render() {
        return (
            <>
                <Preloader/>
                <HomeHeader/>
                <div className="main-content">

                    <HomeContent/>
                    <HomeFooter/>
                </div>
            </>
        );
    }
}

export default Home;
