import React, {Component} from 'react';
import AboutHeader from "./AboutHeader";
import AboutFooter from "./AboutFooter";
import AboutContent from "./AboutContent";
import Preloader from "../global/Preloader";

class About extends Component {
    render() {
        return (
            <>
                <Preloader />
                <AboutHeader />
                <AboutContent />
                <AboutFooter />
            </>
        );
    }
}

export default About;
