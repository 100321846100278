import React, {Component} from 'react';
import {Link} from "@reach/router";

class NextPage extends Component {
    render() {
        const {NextPageInfo} = this.props;
        return (
            <>
                <section className="call-action section-padding sub-bg bg-img" data-background="img/pattern.png">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-9">
                                <div className="content sm-mb30">
                                    <h6 className="wow" data-splitting>{NextPageInfo.smallHeader}</h6>
                                    <h2 className="wow custom-font" data-splitting dangerouslySetInnerHTML={{__html: NextPageInfo.bigHeader}}></h2>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-3 valign">
                                <Link to={NextPageInfo.btn.btnLink} onClick={() => window.location.replace( window.location.history.pathname + NextPageInfo.btn.btnLink)} className="btn-curve btn-lit wow fadeInUp"
                                   data-wow-delay=".5s"><span>{NextPageInfo.btn.btnText}</span></Link>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default NextPage;
