import React, {Component} from 'react';

class ContactFooter extends Component {
    render() {
        return (
            <>
                <footer className="footer-half sub-bg">
                    <div className="container">
                        <div className="copyrights text-center mt-0">
                            <p>© 2020, AllAndMuchMore Developed by <a href="#0">AllAndMuchMore Team.</a>.</p>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default ContactFooter;