import React, {Component} from 'react';

class HomeAboutUsSection extends Component {
    render() {
        return (
            <>
                <div className="about section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-mons">
                                    <div className="row">
                                        <div className="col-md-5 cmd-padding valign">
                                            <div className="img1 wow imago" data-wow-delay=".5s">
                                                <img src="img/intro/1.webp" alt="about"/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 cmd-padding">
                                            <div className="img2 wow imago" data-wow-delay=".3s">
                                                <img src="img/intro/3.webp" alt="about"/>
                                            </div>
                                            <div className="img3 wow imago" data-wow-delay=".8s">
                                                <img src="img/intro/2.webp" alt="about"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 valign">
                                <div className="content">
                                    <div className="sub-title">
                                        <h6>About Us</h6>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <h3 className="main-title wow" data-splitting>OUR MESSAGE </h3>
                                    <p className="wow txt" data-splitting>At AllandMuchMore, we strive to provide all digital services with high quality,
                                        hoping to achieve the ambition of customers aspiring to success and make money using everything new and innovative in digital marketing and digital services,
                                        The best solutions and technologies with AllandMuchMoreare now on the ground.</p>
                                    <div className="ftbox mt-30">
                                        <ul>
                                            <li className="wow fadeIn" data-wow-delay=".3s">
                                                <span className="icon pe-7s-gleam"></span>
                                                <h6 className="custom-font">Heigh <br/> quality</h6>
                                                <div className="dots">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </li>
                                            <li className="space wow fadeIn" data-wow-delay=".5s">
                                                <span className="icon pe-7s-paint-bucket"></span>
                                                <h6 className="custom-font">Creative <br/> Design</h6>
                                                <div className="dots">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </li>
                                            <li className="wow fadeIn" data-wow-delay=".8s">
                                                <span className="icon pe-7s-medal"></span>
                                                <h6 className="custom-font">Heigh <br/> Perfomance</h6>
                                                <div className="dots">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default HomeAboutUsSection;
