import React, {Component} from 'react';
import {Link} from "@reach/router";

class WorkSlideItem extends Component {
    render() {
        const {worksItems} = this.props;
        return (
            <>
                {
                    worksItems.map((workItem, i)=> (
                        <div className="swiper-slide" key={i}>
                            <div className="content wow noraidus fadeInUp" data-wow-delay=".3s">
                                <div className="item-img bg-img wow imago"
                                     data-background={workItem.backgroundImage}>
                                </div>
                                <div className="cont">
                                    <h6><Link to={workItem.category.catLink}>{workItem.category.catText}</Link></h6>
                                    <h4><Link to={workItem.title.titleLink}>{workItem.titleText}</Link></h4>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </>
        );
    }
}

export default WorkSlideItem;
