import React, {Component} from 'react';
import ShowcaseSlide from "./ShowcaseSlide";

class ShowcaseContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ShowcasesData: [
                {
                    "website-01": [
                        {
                            BackgroundImage: "/img/portfolio/full/project1/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project1/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project1/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project1/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-02": [
                        {
                            BackgroundImage: "/img/portfolio/full/project2/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project2/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project2/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project2/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-03": [
                        {
                            BackgroundImage: "/img/portfolio/full/project3/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project3/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project3/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project3/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-04": [
                        {
                            BackgroundImage: "/img/portfolio/full/project4/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project4/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project4/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project4/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    
                    "website-05": [
                        {
                            BackgroundImage: "/img/portfolio/full/project5/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project5/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project5/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project5/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-06": [
                        {
                            BackgroundImage: "/img/portfolio/full/project6/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project6/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project6/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project6/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-07": [
                        {
                            BackgroundImage: "/img/portfolio/full/project7/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project7/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project7/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project7/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-08": [
                        {
                            BackgroundImage: "/img/portfolio/full/project8/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project8/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project8/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project8/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-09": [
                        {
                            BackgroundImage: "/img/portfolio/full/project9/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project9/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project9/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project9/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "website-10": [
                        {
                            BackgroundImage: "/img/portfolio/full/project10/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project10/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project10/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project10/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "identity": [
                        {
                            BackgroundImage: "/img/portfolio/full/project11/1.jpg",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project11/2.jpg",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project11/3.jpg",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project11/4.jpg",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                    "business-card": [
                        {
                            BackgroundImage: "/img/portfolio/full/project12/1.jpg",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project12/2.jpg",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project12/3.jpg",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project12/4.jpg",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                    "brochure": [
                        {
                            BackgroundImage: "/img/portfolio/full/project13/1.jpg",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project13/2.jpg",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project13/3.jpg",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project13/4.jpg",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                    "social-media": [
                        {
                            BackgroundImage: "/img/portfolio/full/project14/1.jpg",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project14/2.jpg",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project14/3.jpg",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project14/4.jpg",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                    "proms": [
                        {
                            BackgroundImage: "/img/portfolio/full/project15/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project15/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project15/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project15/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "smpos": [
                        {
                            BackgroundImage: "/img/portfolio/full/project16/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project16/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project16/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project16/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "letsdiet": [
                        {
                            BackgroundImage: "/img/portfolio/full/project17/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project17/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project17/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project17/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "qcom": [
                        {
                            BackgroundImage: "/img/portfolio/full/project18/1.jpg",
                            phase1: "Modern",
                            phase2: "Design",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project18/2.jpg",
                            phase1: "Compatible",
                            phase2: "Responsive",
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project18/3.jpg",
                            phase1: "Easy",
                            phase2: "To use"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project18/4.jpg",
                            phase1: "Clean",
                            phase2: "Code"
                        },
                    ],
                    "motorgy": [
                        {
                            BackgroundImage: "/img/portfolio/full/project19/1.gif",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project19/2.gif",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project19/3.gif",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project19/4.gif",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                    "motion-promo": [
                        {
                            BackgroundImage: "/img/portfolio/full/project20/1.gif",
                            phase1: "We are",
                            phase2: "Creative"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project20/2.gif",
                            phase1: "Creative",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project20/3.gif",
                            phase1: "Stylish",
                            phase2: "Design"
                        },
                        {
                            BackgroundImage: "/img/portfolio/full/project20/4.gif",
                            phase1: "Be",
                            phase2: "Special"
                        },
                    ],
                },
            ],
        }
    }

    render() {
        const {showcaseSlug} = this.props;
        const {ShowcasesData} = this.state;
        return (
            <>
                <header className="slider showcase-full">
                    <div className="swiper-container parallax-slider">
                        <div className="swiper-wrapper">
                            <ShowcaseSlide showcasesSlides={ShowcasesData} showcaseSlug={showcaseSlug}/>

                        </div>

                        <div className="txt-botm">
                            <div className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer">
                                <div>
                                    <span className=" custom-font">Next Slide</span>
                                </div>
                                <div><i className="fas fa-chevron-right"></i></div>
                            </div>
                            <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer">
                                <div><i className="fas fa-chevron-left"></i></div>
                                <div>
                                    <span className=" custom-font">Prev Slide</span>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination steps custom-font"></div>
                    </div>
                </header>
            </>
        );
    }
}

export default ShowcaseContainer;