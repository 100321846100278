import React, {Component} from 'react';

class ContactInfo extends Component {
    render() {
        return (
            <>
                <div className="col-lg-5 offset-lg-1">
                    <div className="cont-info">
                        <h4 className="extra-title mb-50">Contact Info.</h4>
                        <h3 className="custom-font wow" data-splitting>Let's Talk.
                        </h3>
                        <div className="item mb-40">
                            <h5><a href="#0">info@allandmuchmore.com</a></h5>

                        </div>
                        <h3 className="custom-font wow" data-splitting>Visit Us.
                        </h3>
                        <div className="item">
                            <h6> Somewhere ON The Planet Earth </h6>
                        </div>
                        <div className="social mt-50">
                            <a href="https://www.facebook.com/AllandMuchMore.CO/" className="icon">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://twitter.com/AllAndMuchMore2" className="icon">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCvNiqQCSeYWgiD5Ez1wDRzQ" className="icon">
                                <i className="fab fa-youtube"></i>
                            </a>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ContactInfo;