import React, {Component} from 'react';
import HomeAboutUsSection from "./HomeAboutUsSection";
import HomeServiceSection from "./HomeServiceSection";
import HomeCounterSection from "./HomeCounterSection";
import HomeWorkSection from "./HomeWorkSection";
import HomeVideoSection from "./HomeVideoSection";
import HomeSkillsCircleSection from "./HomeSkillsCircleSection";
import HomeClientsBrands from "./HomeClientsBrands";
import NextPage from "../global/NextPage";


class HomeContent extends Component {
    render() {
        return (
            <>

                {/*<HomeHeader1 />*/}
                <HomeAboutUsSection/>

                <HomeServiceSection/>
                <HomeCounterSection/>
                <HomeWorkSection/>
                <HomeVideoSection/>
                <HomeSkillsCircleSection/>
                <HomeClientsBrands/>
                <NextPage NextPageInfo={{
                    smallHeader: "Let’s Talk",
                    bigHeader: "about your <b>next project</b>.",
                    btn: {btnText: "Get In Touch", btnLink: "/contact"}
                }}/>
            </>
        );
    }
}

export default HomeContent;
