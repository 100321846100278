import React, {Component} from 'react';
import {Link} from "@reach/router";

class NextSection extends Component {
    render() {
        const {BackgroundImage, slug, title} = this.props;
        return (

            <>
                <section className="next-prog section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="box">
                                    <div className="bg-img valign" data-background={BackgroundImage}
                                         data-overlay-dark="4">
                                        <div className="caption ontop valign">
                                            <div className="o-hidden full-width">
                                                <h1>
                                                    <Link to={"/project/" + slug} onClick={() => window.location.replace("/project/" + slug)}>
                                                        <div className="stroke">{title}</div>
                                                    </Link>
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="copy-cap valign">
                                            <div className="cap full-width">
                                                <h1>
                                                    <Link to={"/project/" + slug} onClick={() => window.location.replace("/project/" + slug)}>
                                                        <span>{title}</span>
                                                    </Link>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default NextSection;