import React, {Component} from 'react';

class WorksHeader extends Component {
    render() {
        return (
            <>
                <header className="works-header fixed-slider hfixd valign">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-11 static">
                                <div className="capt mt-50">
                                    <div className="parlx">
                                        <h2 className="custom-font">Our amazing works</h2>
                                        <p>These are not all of our works but some of them.</p>
                                    </div>

                                    <div className="bactxt custom-font valign">
                                        <span className="full-width">Works</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default WorksHeader;
