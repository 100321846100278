import React, {Component} from 'react';
import ClientReview from "./ClientReview";

class HomeVideoSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientReviews: [
                {
                    reviewText: "You have to be burning with an idea, or a problem, or a wrong that you want to right. If you’are not passionate enough from the start, you'll never stick it out.",
                    author: {authorName: "Steve Jobs", authorDetails: "co-founder and CEO, Apple Inc."},
                    clientImage: "img/clients/1.jpg"
                },
                {
                    reviewText: "We all need people who will give us feedback. That’s how we improve.",
                    author: {authorName: "bill gates", authorDetails: "Co-founder, Microsoft Corporation"},
                    clientImage: "img/clients/2.jpg"
                },
                {
                    reviewText: "When something is important enough, you do it even if the odds are not in your favor..",
                    author: {authorName: "Elon Musk", authorDetails: "Co-founder, SpaceX"},
                    clientImage: "img/clients/3.jpg"
                },
            ],
        };
    }

    render() {
        const {clientReviews} = this.state;
        return (
            <>
                <section className="block-sec">
                    <div className="background bg-img section-padding pb-0" data-background="/img/slid/1.jpg"
                         data-overlay-dark="8">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="vid-area">
                                        <div className="vid-icon">
                                            <a className="vid" href={"https://www.youtube.com/watch?v=cCFLdfm570c"}>
                                                <div className="vid-butn">
                                            <span className="icon">
                                                <i className="fas fa-play"></i>
                                            </span>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="cont">
                                            <h3 className="wow" data-splitting={"words"}>So that's us. There's no other
                                                way to put
                                                it.</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 offset-lg-1">
                                    <div className="testim-box">
                                        <div className="head-box">
                                            <h6 className="wow fadeIn" data-wow-delay=".5s">Successful tnd</h6>
                                            <h4 className="wow fadeInLeft" data-wow-delay=".5s">Quotes we work with</h4>
                                        </div>
                                        <div className="slic-item wow fadeInUp" data-wow-delay=".5s">

                                            <ClientReview Reviews={clientReviews} />


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeVideoSection;
